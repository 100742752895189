import React from 'react';

const Banner = () => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Ryecliff, LLC</h1>
      </header>
      <div className="content">
        <p>
            Unified IT, Simplified.
          <br />
          <br />
            We deal with technology, so you don&apos;t have to.
        </p>
      </div>
      <div>
        <a href="#one" className="button scrolly">Learn More</a>
      </div>
    </div>
  </section>
);

export default Banner;
