import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Banner from '../components/Banner';

import servicesPicture from '../assets/images/services.jpg';
import voipPicture from '../assets/images/voip.jpg';
import securityPicture from '../assets/images/security.jpg';
import aboutUsPicture from '../assets/images/about.jpg';

const Home = () => (
  <Layout>
    <Helmet
      title="Ryecliff, LLC"
      meta={[
        { name: 'description', content: 'Ryecliff, LLC is the only one stop shop for all of your IT and security needs.' },
        { name: 'keywords', content: 'IT, security' },
      ]}
    />

    <Banner />

    <div id="main">
      <section id="one" className="tiles">
        <article style={{ backgroundImage: `url(${servicesPicture})` }}>
          <header className="major">
            <h3>Services</h3>
            <p>IT projects can sometimes be overwhelming, our team of experts will help your project finish on time, and stay within budget.</p>
          </header>
          <Link to="/services" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${voipPicture})` }}>
          <header className="major">
            <h3>Solutions</h3>
            <p>We offer and support VOIP and standard PBX phone systems for all types of businesses. Contact us today for a free quote.</p>
          </header>
          <Link to="/solutions" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${securityPicture})` }}>
          <header className="major">
            <h3>Security</h3>
            <p>Security is a major concern for businesses. The lack of security, is an even bigger concern. Let our trained professionals help you.</p>
          </header>
          <Link to="/security" className="link primary" />
        </article>
        <article style={{ backgroundImage: `url(${aboutUsPicture})` }}>
          <header className="major">
            <h3>About Us</h3>
          </header>
          <Link to="/about" className="link primary" />
        </article>
      </section>
    </div>

  </Layout>
);

export default Home;
